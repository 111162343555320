var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "q-form",
    { ref: "editForm" },
    [
      _c(
        "c-card",
        {
          staticClass: "cardClassDetailForm",
          attrs: { title: "셀렉트 컴포넌트", height: "700px" },
        },
        [
          _c("template", { slot: "card-detail" }, [
            _c(
              "div",
              { staticClass: "col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3" },
              [
                _c("c-select", {
                  attrs: {
                    editable: _vm.editable,
                    comboItems: _vm.comboItems,
                    itemText: "codeName",
                    itemValue: "code",
                    label: "기본",
                    name: "col1",
                  },
                  model: {
                    value: _vm.data.col1,
                    callback: function ($$v) {
                      _vm.$set(_vm.data, "col1", $$v)
                    },
                    expression: "data.col1",
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3" },
              [
                _c("c-select", {
                  attrs: {
                    editable: _vm.editable,
                    comboItems: _vm.comboItems,
                    itemText: "codeName",
                    itemValue: "code",
                    label: "",
                    name: "col1",
                  },
                  model: {
                    value: _vm.data.col1,
                    callback: function ($$v) {
                      _vm.$set(_vm.data, "col1", $$v)
                    },
                    expression: "data.col1",
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3" },
              [
                _c("c-select", {
                  attrs: {
                    required: true,
                    editable: _vm.editable,
                    comboItems: _vm.comboItems,
                    itemText: "codeName",
                    itemValue: "code",
                    label: "필수값",
                    name: "col1",
                  },
                  model: {
                    value: _vm.data.col1,
                    callback: function ($$v) {
                      _vm.$set(_vm.data, "col1", $$v)
                    },
                    expression: "data.col1",
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3" },
              [
                _c("c-select", {
                  attrs: {
                    disabled: true,
                    editable: _vm.editable,
                    comboItems: _vm.comboItems,
                    itemText: "codeName",
                    itemValue: "code",
                    label: "disable",
                    name: "col1",
                  },
                  model: {
                    value: _vm.data.col1,
                    callback: function ($$v) {
                      _vm.$set(_vm.data, "col1", $$v)
                    },
                    expression: "data.col1",
                  },
                }),
              ],
              1
            ),
          ]),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }